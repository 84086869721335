<template>
    <div style="margin-left: auto; height: 48px;">
        <v-btn style="width: 8em;" class="mr-4 mt-2" v-if="streamLoading">
            <v-progress-circular
                style="height: 1.5em"
                indeterminate
                color="secondary"/>
        </v-btn>
        <v-btn v-else-if="streamPlaying" style="width: 8em" class="mr-4 mt-2" color="red" @click="stopStream()">{{ $t('streaming.stop_stream_mobile') }}</v-btn>
        <v-btn v-else style="width: 8em" class="mr-4 mt-2" color="primary" @click="startStream()">{{ $t('streaming.start_stream_mobile') }}</v-btn>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
    export default {
        methods: {
            ...mapActions('streaming', [
                'startStream',
                'stopStream'
        ])
        },
        computed: {
            ...mapGetters('streaming', [
                'streamLoading',
                'streamPlaying'
            ])
        }
    }
</script>